import React from 'react';
import style from './HeroBtn.module.css';

const HeroBtn = (prop: any) => {
    return (
        <button className={`${style.btn} ${style.btnPosition} ${style.btnText}`}
            onClick={prop.handleClick}>
            {prop.text}
        </button>
    )
}

export default HeroBtn
