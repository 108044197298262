import React, { useState } from 'react';
import style from './TextSlider.module.css';
import TextContent from './textContent/TextContent';
import { SLIDE_VW} from '../../../../../data/constant';
import SliderBtn from '../../../buttons/sliderBtn/SliderBtn';

import data from '../../../../../data/textSlide.json';
import Dots from '../../../buttons/dots/Dots';

const sliderChanger = (property: any, value: any) => {
    document.documentElement.style.setProperty(property, value);
}

const TextSlider = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const slideWidth = 100;

    const nextSlide = () => {
        if (slideIndex === data.length - 1) {
            setSlideIndex(0)
            sliderChanger(SLIDE_VW, 0 + 'vw');
        }
        else {
            setSlideIndex(slideIndex + 1)
            let slide = (slideIndex + 1) * - slideWidth
            sliderChanger(SLIDE_VW, slide + 'vw');

        }
    }

    const prevSlide = () => {
        console.log("top", slideIndex)
        if (slideIndex === 0) {
            setSlideIndex(data.length - 1)
            sliderChanger(SLIDE_VW, (data.length - 1) * (-slideWidth) + 'vw');
        }
        else {
            setSlideIndex(slideIndex - 1)
            let slide = (slideIndex - 1) * - slideWidth
            sliderChanger(SLIDE_VW, slide + 'vw');
        }
    }

    const dotClickCallBack = (index:any) =>{
        setSlideIndex(index)
        let slide = (index) * - slideWidth
        sliderChanger(SLIDE_VW, slide + 'vw');
    }

    return (
        <div className={style.container}>
            <TextContent />
            <SliderBtn handleBtnClick={prevSlide} />
            <SliderBtn direction={'right'} handleBtnClick={nextSlide} />
            <Dots activeId={slideIndex} dotClick={dotClickCallBack}/>
        </div>
    )

}

export default TextSlider
