import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Navbar from '../library/navbar/NavbarReformat';
import Home from '../pages/home/Home'
import Footer from '../library/footer/footer';
import Gallery from '../pages/gallery/Gallery';
import Contact from '../pages/contact/Contact';

const Routes = () => {
    return (
        <Router>
                <Navbar />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/shop">
                    </Route>
                    <Route path="/game">
                        {"HELLO 1"}
                    </Route>
                    <Route path="animation">
                        
                    </Route>
                    <Route path="/project">
                        <Gallery />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>

                </Switch>
                <Footer/>
        </Router>
    )
}

export default Routes
