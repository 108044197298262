import React from 'react'
import Dot from './Dot'
import style from './Dots.module.css'
import data from '../../../../data/textSlide.json'

const Dots = ({activeId, dotClick}:any) => {

 
    const handleDot = (id:any) =>{
       const dotClickCall = dotClick
       dotClickCall(id)
    }

    return (
        <div className={style.dotContainer}>
            {data.map((data)=>{
                const active = activeId === data.id ? style.active : '';
                return <Dot key={data.id} activeClass={active} handleDotClick={()=>handleDot(data.id)}/>
            })}
        </div>
    )
}

export default Dots
