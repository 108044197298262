import React from "react";
import style from "./ContactUs.module.css";
import SocialBtn from '../buttons/round/social/SocialBtn';

import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram-icon.svg'
import { ReactComponent as GithubIcon } from '../../../assets/icons/github-icon.svg'
import { ReactComponent as LinkedInIcon } from '../../../assets/icons/linkedin-icon.svg'
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook-icon.svg'

import LineInput from '../inputs/lineInput/LineInput'
import HeroBtn from "../buttons/heroBtn/HeroBtn";

const ContactUs = () => {

    const [name, setName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);

    const handleSubmit = (event: any) => {
        console.log(`
        Name: ${name}
        LastName: ${lastName}
        Email: ${email}
        Phone: ${phone}
        Country: ${country}
        Accepted Terms: ${acceptedTerms}
      `);

        event.preventDefault();
    }

    return (
        <div className={style.container}>
            <div className={style.myContact}>
                <h2 className={style.myContactTitle}>Contact Information</h2>
                <p className={style.myContactInfo}>Submit the form <br></br> We will get back to you</p>

                <div className={style.myContactUs}>
                    <GithubIcon style={{ width: 30, height: 30, margin: "6px 0" }} />
                    <p>(718) 772-1837</p>

                    <GithubIcon style={{ width: 30, height: 30, margin: "6px 0" }} />
                    <p>Norbertostudios@gmail.com</p>
                </div>
                <div className={style.myContactSocial}>
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<GithubIcon className={style.social} />}
                    />
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<LinkedInIcon  className={style.social}/>}
                    />
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<InstagramIcon className={style.social} />}
                    />
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<FacebookIcon className={style.social} />}
                    />
                </div>
            </div>
            <div className={style.contact}>
                <form className={style.contactForm} onSubmit={handleSubmit}>
                    <label>
                        Name
                        <input
                            type="text"
                            name="name"
                            placeholder="John"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        LastName
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Doe"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Email
                        <input
                            type="email"
                            name="Email"
                            placeholder="JohnDoe@gmail.com"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Phone
                        <input
                            type="phone"
                            name="phone"
                            placeholder="(718) 000-0000"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </label>
                    <h3>Select Your Interest</h3>
                    <select>
                        <option selected value="webDevelopment">Web Development</option>
                        <option value="appDevelopment">App Development</option>
                        <option value="animation">Animation</option>
                        <option value="games">Games</option>
                        <option value="3dModeling">3D Modeling</option>
                    </select>
                    <h3>Message</h3>
                    <textarea>
                        Hello there, this is some text in a text area
                    </textarea>
                    {/* <button>Submit</button> */}
                    {/* <HeroBtn text="Submit" /> */}
                    {/* <input type="submit" value="Submit" /> */}
                </form>
                {/* <LineInput /> */}

            </div>

        </div>
    );
};

export default ContactUs;