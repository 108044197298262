import React from 'react'
import TextSlider from '../sections/slider/text/TextSlider';
// import style from './Hero.module.css';

const Hero = () => {
    return (
        <div >
            <TextSlider/>
        </div>
    )
}

export default Hero
