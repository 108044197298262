import React from 'react'
import Arrow from '../../icons/arrow/Arrow'
import globalBtnStyle from '../Buttons.module.css'
import style from './SliderBtn.module.css'

const SliderBtn = ({direction, handleBtnClick}:any) => {
    const left = 130;
    const right = -45;
    const dir = direction === "right" ? right : left;

    const styleDir = dir === right ? style.right : style.left
    const styleArrowDir = dir === right ? style.arrowRight : style.arrowLeft
    return (
        <button className={`${globalBtnStyle.btn} ${style.button} ${styleDir}`} onClick={handleBtnClick}>
            <Arrow degree={dir} second={styleArrowDir}/>
        </button>
    )
}

export default SliderBtn
