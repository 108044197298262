import React from 'react';
import style from './SocialBtn.module.css';
import globalstyle from '../../Buttons.module.css';

const SocialBtn = ({icon, extraClass}:any) => {
    return (
        <button className={`${extraClass} ${style.container} ${globalstyle.btn} `}>
          {icon}
        </button>
    )
}

export default SocialBtn
