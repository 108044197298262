import React from 'react';
import style from './TextContent.module.css';
import data from '../../../../../../data/textSlide.json';
import TextSlide from '../textSlide/TextSlide';
import Cube from '../../../../backgrounds/cube/Cube';

const handleClick = (data: any) => {
    console.log(data)
}
const bg = [<Cube />]

const TextContent = () => {
    return (
        <div className={style.test}>
            {data.map((slide) => {
                return (
                    <TextSlide key={slide.id}
                        title={slide.title}
                        btnText={slide.btnText}
                        handleClick={() => handleClick(slide.id)}
                        bg={bg[slide.id]}
                    />
                )
            })
            }
        </div >
    )
}

export default TextContent
