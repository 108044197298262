import React from 'react'
import style from './Home.module.css';
import Hero from '../../library/hero/Hero';
import Section1 from '../../library/sections/section1/Section1';
import Introduction from '../../library/sections/introduction/Introduction';
import Services from '../../library/sections/ourServices/Services';
import ShopHome from '../../library/sections/shopHome/ShopHome';

const Home = () => {

    return (
        <div className={style.container}>
            <Hero />
            <Introduction/>
            {/* <Services/> */}
            {/* <ShopHome/> */}
            {/* <Section1/> */}
        </div>
    )
}

export default Home
