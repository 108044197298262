import React from 'react'
import style from './Title.module.css';

const Title = ({title}:any) => {
    return (
        <div className={style.title}>
             <h1>{title}</h1>
         </div>
    )
}

export default Title
