import React from 'react';
import style from './GallerySection.module.css';

const Gallery_section = () => {
    return (
        <div className={style.img_container}>
        <div className={style.img_wrap}>
          <img
            src="https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA22942_hires.jpg"
            alt="Juno's Latest Flyby of Jupiter Captures Two Massive Storms"
          />
        </div>
        <div className={style.img_wrap}>
          <img
            src="https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA22942_hires.jpg"
            alt="Juno's Latest Flyby of Jupiter Captures Two Massive Storms"
          />
        </div>
        <div className={style.img_wrap}>
          <img
            src="https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA22942_hires.jpg"
            alt="Juno's Latest Flyby of Jupiter Captures Two Massive Storms"
          />
        </div>
        <div className={style.img_wrap}>
          <img
            src="https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA22942_hires.jpg"
            alt="Juno's Latest Flyby of Jupiter Captures Two Massive Storms"
          />
        </div>
        <div className={style.img_wrap}>
          <img
            src="https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA22942_hires.jpg"
            alt="Juno's Latest Flyby of Jupiter Captures Two Massive Storms"
          />
        </div>
      </div>
    )
}

export default Gallery_section
