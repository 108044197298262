import React from 'react'
import style from './Dots.module.css'
import globalBtnStyle from '../Buttons.module.css';

const Dot = ({activeClass, handleDotClick}:any) => {
    return (
        <button className={`${style.dot} ${activeClass} ${globalBtnStyle.btn}`} onClick={handleDotClick}>
            
        </button>
    )
}

export default Dot
