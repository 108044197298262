import React from "react";
import Title from "../../library/sections/title/Title";
import GallerySection from '../../library/sections/gallerySection/GallerySection';
import style from "./Gallery.module.css";

const Gallery = () => {
  return (
    <div className={style.container}>
      <Title title={"GALLERY"} />
      <GallerySection />

     </div>
  );
};

export default Gallery;
