import React from 'react';
import style from './Arrow.module.css';

const Arrow = ({degree, second}:any) => {

    return (
        <div className={`${style.arrow} ${second}`} style={{transform: `rotate(${degree}deg)`}}>
        </div>
    )
}

export default Arrow
