import React from 'react'
import style from './TextSlide.module.css';
import HeroBtn from '../../../../buttons/heroBtn/HeroBtn';

const TextSlide = ({ title, btnText, handleClick, bg }: any) => {
    return (
        // <div>
        //     <div className={style.container}>
        //         <h1 className={style.bgTitle}>{title}</h1>
        //     </div>
            <div className={style.slideContainer}>
                <h1 className={style.title}>{title}</h1>
                <HeroBtn text={btnText} handleClick={handleClick} />
                {bg}
            </div>

        // </div>
    )
}

export default TextSlide
