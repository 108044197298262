import React from 'react';
import style from './Cube.module.css';

const Cube = () => {
    return (
        <div className={style.container}>
     
            <div className={style.cube}></div>
            <div className={style.cube}></div>
            <div className={style.cube}></div>
            <div className={style.cube}></div>
            <div className={style.cube}></div>
            <div className={style.cube}></div>
            <div className={style.cube}></div>
            <div className={style.cube}></div>
         
     </div>
    )
}

export default Cube
