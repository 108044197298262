import React from 'react';
import '../globalStyles/Theme.css';
import Routes from './route/Routes';

function App() {

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
