import React from 'react';
import style from './Introduction.module.css'

const Introduction = () => {
    return (
        <div className={style.container}>
            <h1 className={style.title}>
                Dream Develop Deploy
                </h1>
            <div className={style.paragraph}>
                <p>
                    If you can dream it. We can develop it. <br></br>

                        If we can develop it. Then we can deploy it.<br></br>

                        If we deploy it to the web or store. Our clients and customer are happy.<br></br>
                </p>
                <p>
                    We at Norberto Studios can design and produce software applications.<br></br>

                        We have a passion for Web, Mobile app, and game development.<br></br>

                        We can create 3d assets and animation as well.<br></br>
                </p>
                <p>
                    For all of your needs we are here to help.
                    </p>
            </div>
            <div className={style.imageBG}></div>
        </div>
    )
}

export default Introduction
