import React from "react";
import Title from "../../library/sections/title/Title";
import ContactUs from '../../library/contact/ContactUs';
import style from "./Contact.module.css";

const Contact = () => {
    return (
        <div className={style.container}>
            <Title title={"CONTACT US"} />
            <ContactUs />
        </div>
    );
};

export default Contact;
