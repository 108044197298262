import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from "./NavbarReformat.module.css";
// import LoginBtn from "../buttons/loginBtn/NavLoginBtn";
import { ReactComponent as Hamburger } from "./../../../assets/icons/ham.svg";
import { ReactComponent as CloseX } from "./../../../assets/icons/X.svg";

const NavbarReformat = () => {
  const [toggle, settoggle] = useState(false);
  const [active, setActive] = useState("home");

  const menuToggle = () => {
    settoggle(!toggle);
  };
  const menuActive = (e:any) => {
    setActive(e.target.title)
    menuToggle();
  };

  const activeMenu = `${style.a} ${style.activeLink}`
  return (
    <nav >
      <Link to={"/"} className={style.logoContainer} >
        {/* <img className={style.logo} src="" alt="Norberto Studios Logo" /> */}
        <h1>NorbertoStudios</h1>
      </Link>
        <ul className={toggle ? style.toggle : ""}>
          <li>
            <Link className={active === "home" ? activeMenu : style.a } 
            to={"/"} 
            onClick={menuActive} 
            title={"home"}>
              Home
            </Link>
          </li>
          <li>
            <Link className={active === "shop" ? activeMenu : style.a } 
            to={"/shop"} 
            onClick={menuActive} 
            title={"shop"}>
              Shop
            </Link>
          </li>
          <li>
            <Link className={active === "game" ? activeMenu : style.a } 
            to={"/game"} 
            onClick={menuActive} 
            title={"game"}>
              Game
            </Link>
          </li>
          <li>
            <Link className={active === "animation" ? activeMenu : style.a} 
            to={"/animation"} 
            onClick={menuActive} 
            title={"animation"}>
              Animation
            </Link>
          </li>
          <li>
            <Link className={active === "projects" ? activeMenu : style.a} 
            to={"/projects"} 
            onClick={menuActive} 
            title={"projects"}>
              Projects
            </Link>
          </li>
          <li>
            <Link className={active === "contact" ? activeMenu : style.a} 
            to={"/contact"} 
            onClick={menuActive} 
            title={"contact"} >
              Contact
            </Link>
          </li>
          {/* <li>
            <Link className={style.a} to={"/login"} onClick={menuToggle}>
              login
            </Link>
          </li> */}
          {/* <li className={style.close} onClick={menuToggle}>
           X
          </li> */}
            {/* //Need a static for mobile  */}
          {/* <li className={style.loginBtn}>
          <LoginBtn />
          </li> */}
{/* 
          <div className={style.loginBtn}>
              <LoginBtn />
            </div> */}
        </ul>
      <div className={style.menu} onClick={menuToggle}>
        {toggle ? <CloseX className={style.rect} /> :
        <Hamburger className={style.rect}  />
          }
        </div>
    </nav> 
  );
};

export default NavbarReformat;
