import React from 'react';
import style from './Footer.module.css';
import SocialBtn from '../buttons/round/social/SocialBtn';

import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram-icon.svg'
import { ReactComponent as GithubIcon } from '../../../assets/icons/github-icon.svg'
import { ReactComponent as LinkedInIcon } from '../../../assets/icons/linkedin-icon.svg'
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook-icon.svg'

const Footer = () => {
    return (
        <div className={style.container}>
            <div className={style.copyright}><h3>Norberto Studios | ©2021 COPYRIGHT. ALL RIGHT RESERVED </h3></div>
            <div className={style.networkContainer}>
                <h2 className={style.title}>Our Social Networks</h2>
                <div className={style.socialIconsContainer}>
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<GithubIcon style={{ width: 30, height: 30, margin: "6px 0" }} />}
                    />
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<LinkedInIcon style={{ width: 24, height: 24, margin: "4px 0" }} />}
                    />
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<InstagramIcon style={{ width: 29, height: 29, margin: "6px 0" }} />}
                    />
                    <SocialBtn
                        extraClass={style.socialIcons}
                        icon={<FacebookIcon style={{ width: 26, height: 26, margin: "6px 0" }} />}
                    />
                </div>
            </div>

        </div>
    )
}

export default Footer
